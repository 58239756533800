<template>
    <div>
        <Toast />
        <div class="card">
            <div class="p-grid p-fluid">
                <div class="p-col-12">
                    <h5>{{$t('Bulletins.search.searchHeading')}}</h5>
                    <div class="p-formgrid p-grid">
                        <div class="p-field p-col-12 p-md-3">
                            <label for="type">{{$t('Bulletins.search.Title')}}</label>
                            <InputText id="googlurl" type="text" :placeholder="$t('Bulletins.search.SBTitle')" v-model="title" />
                        </div>
                        <div class="p-field p-col-12 p-md-3">
                            <label for="pass">{{$t('Bulletins.search.Country')}}</label>
                            <Dropdown v-model="country_id" :options="dropdownCountryValues" optionValue="id"
                                optionLabel="country_name" :placeholder="$t('Bulletins.search.Select') " />
                        </div>

                        <div class="p-field p-col-12 p-md-3">
                            <label for="pass">{{$t('Bulletins.search.Category')}}</label>
                            <Dropdown v-model="category_id" :options="dropdownBoardCategoryValues" optionValue="id"
                                optionLabel="title" :placeholder="$t('Bulletins.search.Select') " @click="getCategory" />
                        </div>

                        <div class="p-field p-col-12 p-md-3">
                            <label for="pass">{{$t('Bulletins.search.PostedDate')}}</label>
                            <Calendar inputId="range" v-model="dateRange" selectionMode="range" :manualInput="false"
                                placeholder="YYYY/MM/DD - YYYY/MM/DD" :showIcon="true"></Calendar>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-d-flex p-jc-between p-ai-lg-center p-ai-start p-mt-6 p-flex-column p-flex-lg-row">
                <div class="p-mb-4 p-mb-lg-0"></div>
                <div>
                    <Button :label="$t('button.search')" icon="pi pi-search" iconPos="left" class="p-button p-button-sm p-mr-2 p-mb-2"
                        @click="searchBulletinManagement()"></Button>
                    <Button :label="$t('button.reset')" icon="pi pi-replay" iconPos="left" class="p-button p-button-sm p-mr-2 p-mb-2"
                        @click="resetBulletinManagement()"></Button>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="p-grid">
                <div class="p-col-12">
                    <div class="p-d-flex p-jc-between p-mb-2">
                        <h4>{{$t('Bulletins.list.Heading')}}</h4>
                        <div>
                            <router-link to="/BulletinManagementAdd">
                                <Button :label="$t('Bulletins.list.AddNew')" class="p-button p-button-outlined p-button-sm p-mr-2 p-mb-2"
                                    style="background: black; color: white"> </Button>
                            </router-link>
                        </div>
                    </div>
                    <DataTable :value="products" :paginator="true" class="p-datatable-gridlines" :rows="10" dataKey="id"
                        :rowHover="true" :loading="loading1" :filters="filters1" responsiveLayout="scroll"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        :rowsPerPageOptions="[10, 25, 50]"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries">
                        <ConfirmDialog group="dialog" />

                        <template #empty>{{$t('Nodata')}}</template>
                        <template #loading>Loading data. wait.</template>

                        <Column field="#" header="#" style="min-width: 5rem">
                            <template #body="{ data }">
                                <span class="p-column-title">Sl. No.</span>
                                {{ data.sl_no }}
                            </template>
                        </Column>
                        <Column field="companyname" :header="$t('Bulletins.list.Title')" style="min-width: 13rem">
                            <template #body="{ data }">
                                <span class="p-column-title">Title</span>
                                {{ data.title }}
                            </template>
                        </Column>
                        <Column field="product" :header="$t('Bulletins.list.Description')" style="min-width: 13rem">
                            <template #body="{ data }">
                                <span class="p-column-title">Description</span>
                                <template class="p-column-title" v-if="data.description.length < 8"> {{
                                    data.description
                                }}</template>
                                <template class="p-column-title" v-else> {{
                                    data.description.substring(0, 8) + ".."
                                }}</template>
                                <!-- {{ data.description }} -->
                            </template>
                        </Column>
                        <Column field="Posted By" :header="$t('Bulletins.list.PostedBy')" style="min-width: 12rem">
                            <template #body="{ data }">
                                <span class="p-column-title">Posted By</span>
                                {{ data.posted_by }}

                            </template>
                        </Column>
                        <Column field="service" :header="$t('Bulletins.list.Category')" style="min-width: 12rem">
                            <template #body="{ data }">
                                <span class="p-column-title">Category</span>
                                {{ data.category_name }}
                            </template>
                        </Column>
                        <Column field="Status" :header="$t('Bulletins.list.Country')" style="min-width: 12rem">
                            <template #body="{ data }">
                                <span class="p-column-title">Country</span>

                                {{ data.country_name }}
                            </template>
                        </Column>

                        <Column field="Creation-Date" :header="$t('Bulletins.list.Status')" style="min-width: 12rem">
                            <template #body="{ data }">
                                <span class="p-column-title">Exposure</span>
                                <InputSwitch v-model="data.expose" trueValue="1"
                                    @change="switchValue(data.id, data.expose)" />
                            </template>
                        </Column>

                        <Column field="Creation-Date" :header="$t('Bulletins.list.PostedDate')" style="min-width: 12rem">
                            <template #body="{ data }">
                                <span class="p-column-title">Posted Date</span>
                                {{ startdateformat(data.posted_date) }}
                            </template>
                        </Column>

                        <Column field="Actions" :header="$t('Bulletins.list.Action')">
                            <template #body="{ data }">
                                <span class="p-column-title">Actions</span>
                                <p style="display: none">{{ data.status }}</p>
                                <div style="display: flex">
                                    <router-link :to="'/BulletinManagementView/' + data.id">
                                        <Button label="info"
                                            class="n-wrap p-button-outlined p-button-info p-mb-2">
                                            <i class="pi pi-eye p-mr-2"></i>
                                        </Button>
                                    </router-link>
                                    <router-link :to="'/BulletinManagementEdit/' + data.id">
                                        <Button label="help"
                                            class="n-wrap p-button-outlined p-button-help p-mb-2">
                                            <i class="pi pi-pencil p-mr-2"></i>
                                        </Button>
                                    </router-link>

                                    <Button icon="pi pi-trash"
                                        class="n-wrap p-button-danger p-button-outlined p-mb-2"
                                        @click="deleteNote(data.id)" />

                                    <!-- <Button :label="$t('button.delete')" icon="pi pi-trash" class="n-wrap p-button-danger p-button-outlined p-mr-2 p-mb-2" @click="confirm(data.id)" /> -->
                                </div>
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import { useRoute } from 'vue-router';
import BulletinManagementService from '../../../service/API/SuperAdmin/BulletinManagementService.js';
import axios from 'axios';
import moment from 'moment';
export default {
    data() {
        return {
            // dropdownValues: [{ name: 'nft',code:'NFT' }, { name: 'card_news',code:'Card News' }, { name: 'media_press',code:'Media press' }, { name: 'de_fi_services',code:'De-Fi Services' }],
            dropdownstatusValues: [
                { name: 'Active', code: 'active' },
                { name: 'Inactive', code: 'deleted' },
            ],
            serial: 0,
            dropdownstatusValue: null,

            isModalVisible: false,
            dropdownCountryValues: '',
            dropdownCountryValue: null,
            dropdownBoardCategoryValues: '',
            dropdownBoardCategoryValue: null,
            products: null,
            loading1: true,
            deletedID: null,
            sl_no: '',
            title: '',
            country_id: '',
            category_id: '',
            post_date: '',
            status: '',
            total: '',
            id: '',
            description: '',
            posted_date: '',
            from_date: '',
            to_date: '',
            searchdate: '',
            searchenddate: '',
            dateRange: ''
        };
    },
    created() {
        this.bulletinManagementService = new BulletinManagementService();
    },
    mounted() {
        const route = useRoute();
        console.log(route.params);
        this.getCategory()
        this.getBulletlist();
        // this.bulletinManagementService
        //     .getBulletinManagementList(this.title, this.country_id, this.category_id, this.post_date)
        //     .then((res) => {

        //         this.products = res.data.data.board;
        //         this.total = res.data.data.length;
        //         this.loading1 = false;
        //         // this.products.forEach((customer) => (customer.createdDate = new Date(customer.createdDate)));

        //         console.log(res.data.data.board);
        //     })

        //     .catch((err) => console.log(err));

        this.bulletinManagementService
            .getCountrydropdown()
            .then((data) => {
                this.dropdownCountryValues = data.data.data.country;
                // this.products = data;
                this.loading1 = false;
                // this.products.forEach((customer) => (customer.createdDate = new Date(customer.createdDate)));
                console.log(this.dropdownCountryValues);
            })
            .catch((err) => console.log(err));

        this.bulletinManagementService
            .getBoardCategorydropdown()
            .then((data) => {
                this.dropdownBoardCategoryValues = data.data.data.board_category;
                // this.products = data;
                this.loading1 = false;
                // this.products.forEach((customer) => (customer.createdDate = new Date(customer.createdDate)));
                console.log(this.dropdownBoardCategoryValues);
            })
            .catch((err) => console.log(err));
    },
    methods: {
        getBulletlist() {
            const BulletParams = {
                // page: 1,
                // limit: this.pagination.pagination_limit,
                title: this.title,
                country_id: this.country_id,
                category_id: this.category_id,
                from_date: this.searchdate,
                to_date: this.searchenddate,
            };
            this.loading1 = true;
            this.bulletinManagementService
                .getBulletinManagementList(BulletParams)
                .then((res) => {
                    this.products = res.data.data.board;
                    this.totalrecords = res.data.total_records;
                    // this.setPagination(res.data.current_page, res.data.total_pages === 0 ? 1 : res.data.total_pages, res.data.total_records);
                    this.loading1 = false;
                    console.log(res);
                })
                .catch((err) => console.log(err));
        },
        switchValue(ids, switchstatus) {
            console.log(ids, switchstatus);
            var self = this;
            axios({ method: 'post', url: '/asnhist-dev/api/v1/admin/board/change-status', data: { id: ids, expose: switchstatus === false ? '0' : '1' } }).then(function (response) {
                // this.$router.push({ name: 'Banner Management' });
                self.$toast.add({ severity: 'info', summary: '확인.', detail: '상태가 성공적으로 변경되었습니다.', life: 3000 });
                console.log(response);
            });
        },

        getCategory() {

            this.bulletinManagementService.getBoardCategorydropdown(this.country_id , this.category_type).then((res) => {

                this.dropdownBoardCategoryValues = res.data.data.board_category;
                console.log(res);
            });
        },
        addDay(val) {
            const date = new Date(val);
            var dd = date.getDate();
            var mm = date.getMonth() + 1;
            var yyyy = date.getFullYear();
            if (dd < 10) {
                dd = '0' + dd;
            }
            if (mm < 10) {
                mm = '0' + mm;
            }
            return (val = yyyy + '-' + mm + '-' + dd);
        },

        resetBulletinManagement() {
            this.title = '';
            this.country_id = '';
            this.category_id = '';
            this.dateRange = '';
            // this.calendarValue1 = '';
            this.loading1 = true;
            // this.getBulletlist();
            window.location.reload();
        },

        searchBulletinManagement() {
            if (this.title === '' && this.country_id === '' && this.category_id === '' && this.dateRange === '') {
                this.$toast.add({ severity: 'error', summary: '에러 메시지', detail: '검색창을 입력해주세요.', life: 2000 });
            } else {
                if (this.dateRange.at(0) != '') {
                    this.searchdate = this.formatRangeDate(this.dateRange.at(0));
                } else {
                    this.searchdate = '';
                }
                if (this.dateRange.at(1) != '') {
                    this.searchenddate = this.formatRangeDate(this.dateRange.at(1));
                } else {
                    this.searchenddate = '';
                }
                const BulletinParams = {
                    // page: 1,
                    // limit: this.pagination.pagination_limit,
                    title: this.title,
                    country_id: this.country_id,
                    category_id: this.category_id,
                    from_date: this.searchdate,
                    to_date: this.searchenddate,
                };
                this.bulletinManagementService
                    .getBulletinManagementList(BulletinParams)
                    .then((res) => {
                        this.products = res.data.data.board;
                        // this.total = res.data.data.total;
                        this.loading1 = false;
                        console.log(res);
                    })
                    .catch(() => {
                        this.products = [];
                        this.loading1 = false;
                    });
            }
        },

        dateformat(value) {
            if (value) {
                return moment(String(value)).locale('ko').format('ll - LTS');
            }
        },

        startdateformat(value) {
            if (value) {
                // return moment(String(value)).format('YYYY/MM/DD ');
                return moment(String(value)).locale('ko').format('ll');
            }
        },
        formatRangeDate(date) {
            if (date === undefined || date === '') {
                return '';
            }
            return moment(new Date(date)).format('YYYY-MM-DD');
        },

        deleteNote: function (id) {
            console.log(id);
            var self = this;
            this.$confirm.require({
                group: 'dialog',
                header: '삭제',
                message: '정말로 삭제하시겠습니까?',
                icon: 'pi pi-trash',
                acceptLabel: '삭제',
                rejectLabel: '취소',
                accept: () => {
                    axios({ method: 'delete', url: '/asnhist-dev/api/v1/admin/board/delete', data: { deleteIdArray: id } }).then(function (response) {
                        console.log(response);
                        self.bulletinManagementService
                            .getBulletinManagementList(self.title, self.country_id, self.category_id, self.post_date)
                            .then((res) => {
                                self.products = res.data.data.board;
                                //self.total = res.data.data.total;
                                self.loading1 = false;
                                // this.products.forEach((customer) => (customer.createdDate = new Date(customer.createdDate)));

                                console.log(res);
                            })
                            .catch((err) => console.log(err));

                        // let i = this.products.map(data => data.id).indexOf(id);

                        // this.products.splice(i, 1)
                    });
                    this.$toast.add({ severity: 'info', summary: '삭제되었습니다.', detail: '성공적으로 삭제되었습니다.', life: 3000 });
                },
                // reject: () => {
                //     this.$toast.add({ severity: 'error', summary: 'An error occurred', detail: 'You have rejected', life: 3000 });
                // },
            });
        },
    },
};
</script>

<style scoped lang="scss">
::v-deep(.p-datatable-frozen-tbody) {
    font-weight: bold;
}

::v-deep(.p-datatable-scrollable .p-frozen-column) {
    font-weight: bold;
}

::v-deep(.p-progressbar) {
    height: 0.5rem;
    background-color: #d8dadc;

    .p-progressbar-value {
        background-color: #607d8b;
    }
}

::v-deep(.p-datatable .p-sortable-column .p-column-title) {
    display: block;
}

.p-datatable .p-column-filter {
    display: none;
}

.table-header {
    display: flex;
    justify-content: space-between;
}

.customer-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;

    &.status-qualified {
        background: #c8e6c9;
        color: #256029;
    }

    &.status-unqualified {
        background: #ffcdd2;
        color: #c63737;
    }

    &.status-negotiation {
        background: #feedaf;
        color: #8a5340;
    }

    &.status-new {
        background: #b3e5fc;
        color: #23547b;
    }

    &.status-renewal {
        background: #eccfff;
        color: #694382;
    }

    &.status-proposal {
        background: #ffd8b2;
        color: #805b36;
    }
}

.p-progressbar-value.ui-widget-header {
    background: #607d8b;
}

@media (max-width: 640px) {
    .p-progressbar {
        margin-top: 0.5rem;
    }
}

.product-image {
    width: 100px;
    height: 50px;
    // box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.orders-subtable {
    padding: 1rem;
}

.product-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;

    &.status-instock {
        background: #c8e6c9;
        color: #256029;
    }

    &.status-outofstock {
        background: #ffcdd2;
        color: #c63737;
    }

    &.status-lowstock {
        background: #feedaf;
        color: #8a5340;
    }
}

.order-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;

    &.order-delivered {
        background: #c8e6c9;
        color: #256029;
    }

    &.order-cancelled {
        background: #ffcdd2;
        color: #c63737;
    }

    &.order-pending {
        background: #feedaf;
        color: #8a5340;
    }

    &.order-returned {
        background: #eccfff;
        color: #694382;
    }
}

.p-datatable {
    .p-sortable-column {
        &.p-highlight {
            &:hover {
                background: rgb(216 217 243);
                color: #464df2;
            }
        }
    }
}

.true-icon {
    color: #256029;
}

.false-icon {
    color: #c63737;
}

.w-100 {
    width: 100%;
}

.p-button {
    background: #000000;
    border: 1px solid #0a0a0a;
}

.p-button.p-button-info.p-button-outlined,
.p-buttonset.p-button-info>.p-button.p-button-outlined,
.p-splitbutton.p-button-info>.p-button.p-button-outlined {
    background-color: transparent;
    color: #171718;
    border: 0px solid;
}

.p-button.p-button-info.p-button-outlined:hover,
.p-buttonset.p-button-info>.p-button.p-button-outlined,
.p-splitbutton.p-button-info>.p-button.p-button-outlined:hover {
    background-color: transparent;
    color: #171718;
    border: 0px solid;
}

.p-button.p-button-info.p-button-outlined:enabled:active,
.p-buttonset.p-button-info>.p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-info>.p-button.p-button-outlined:enabled:active {
    background: rgba(2, 136, 209, 0.16);
    color: #171718;
    border: 0px solid;
}

.p-button.p-button-help.p-button-outlined,
.p-buttonset.p-button-help>.p-button.p-button-outlined,
.p-splitbutton.p-button-help>.p-button.p-button-outlined {
    background-color: transparent;
    color: #171718;
    border: 0px solid;
}

.p-button.p-button-help.p-button-outlined:hover,
.p-buttonset.p-button-help>.p-button.p-button-outlined,
.p-splitbutton.p-button-help>.p-button.p-button-outlined:hover {
    background-color: transparent;
    color: #171718;
    border: 0px solid;
}

.p-button.p-button-danger.p-button-outlined,
.p-buttonset.p-button-danger>.p-button.p-button-outlined,
.p-splitbutton.p-button-danger>.p-button.p-button-outlined {
    background-color: transparent;
    color: #171718;
    border: 0px solid;
}

.p-button.p-button-danger.p-button-outlined:hover,
.p-buttonset.p-button-danger>.p-button.p-button-outlined,
.p-splitbutton.p-button-danger>.p-button.p-button-outlined:hover {
    background-color: transparent;
    color: #171718;
    border: 0px solid;
}
</style>